/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'my_account',
        title: 'NAV.ACCOUNT.TITLE',
        subtitle: '',
        type: 'group',
        // icon: 'heroicons_outline:home',
        // link: '/dashboard',
        children: [
            {
                id: 'profile',
                title: 'NAV.PROFILE.TITLE',
                type: 'basic',
                icon: {
                    name: 'heroicons_outline:user-circle',
                    type: 'svg',
                },
                link: '/client/profile',
            },
            {
                id: 'health_information',
                title: 'NAV.HEALTH.TITLE',
                type: 'basic',
                icon: {
                    name: 'heroicons_outline:heart',
                    type: 'svg',
                },
                // icon: 'heroicons_outline:gift',
                link: '/client/health',
            },
            {
                id: 'rewards',
                title: 'NAV.EC.REWARDS',
                type: 'basic',
                icon: {
                    name: 'heroicons_outline:gift',
                    type: 'svg',
                },
                // icon: 'heroicons_outline:gift',
                link: '/client/rewards',
            },
            {
                id: 'billing',
                title: 'NAV.BILLING.TITLE',
                type: 'basic',
                icon: {
                    name: 'receipt_long',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:gift',
                link: '/client/billing',
            },
            {
                id: 'files',
                title: 'NAV.FILES.TITLE',
                type: 'basic',
                icon: {
                    name: 'description',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:gift',
                link: '/client/files',
            },
        ],
    },
    {
        id: 'exclusive_content',
        title: 'KoalaPro',
        subtitle: '',
        type: 'group',
        // icon: 'heroicons_outline:video',
        // link: '/dashboard',
        children: [
            {
                id: 'mobile-app',
                title: 'NAV.EC.MOBILE',
                type: 'basic',
                icon: {
                    name: 'smartphone',
                    type: 'mat',
                },
                link: '/client/koalapro',
            },
            {
                id: 'workouts',
                title: 'NAV.EC.WORKOUTS.TITLE',
                type: 'basic',
                icon: {
                    name: 'fitness_center',
                    type: 'mat',
                },
                // icon: 'thumb-up',
                link: '/exclusive-content/workouts',
            },
            {
                id: 'recipes',
                title: 'NAV.EC.RECIPES.TITLE',
                type: 'basic',
                icon: {
                    name: 'restaurant_menu',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:chef',
                link: '/exclusive-content/recipes',
            },
            {
                id: 'nutrition_videos',
                title: 'NAV.EC.NUTRITION.TITLE',
                type: 'basic',
                icon: {
                    name: 'ondemand_video',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:chef',
                link: '/exclusive-content/nutrition',
            },
            {
                id: 'articles',
                title: 'NAV.EC.ARTICLES.TITLE',
                type: 'basic',
                icon: {
                    name: 'newspaper',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:chef',
                link: '/exclusive-content/articles',
            },
            {
                id: 'coupons',
                title: 'NAV.EC.COUPONS.TITLE',
                type: 'basic',
                icon: {
                    name: 'discount',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:chef',
                link: '/exclusive-content/coupons',
            },
            {
                id: '30-days-challenge',
                title: 'NAV.EC.30_day_challenge',
                type: 'basic',
                icon: {
                    name: 'calendar_month',
                    type: 'mat',
                },
                // icon: 'heroicons_outline:chef',
                link: '/client/30-days-challenge',
            },
            {
                id: 'subscriptions',
                title: 'NAV.SUBSCRIPTIONS.TITLE',
                type: 'basic',
                icon: {
                    name: 'subscriptions',
                    type: 'mat',
                },
                link: '/client/subscriptions',
            },
        ],
    },
    {
        id: 'contact_us',
        title: 'NAV.CONTACT',
        subtitle: '',
        type: 'group',
        // icon: 'heroicons_outline:home',
        // link: '/dashboard',
        children: [
            {
                id: 'suggestions',
                title: 'toolbar.suggestion',
                type: 'basic',
                icon: {
                    name: 'contact_support',
                    type: 'mat',
                },
                link: '/suggestions',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
