import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { CognitoService } from 'app/core/cognito/cognito.service';

export const AuthGuard: CanActivateFn | CanActivateChildFn = async (
    route,
    state
) => {
    const router: Router = inject(Router);

    const cognitoService: CognitoService = inject(CognitoService);

    // console.log('AuthGuard', route);
    try {
        // Await the result of isAuthenticated()
        const isAuthenticated = await cognitoService.isAuthenticated();

        if (isAuthenticated) {
            // console.log('AuthGuard', isAuthenticated);
            // User is authenticated, allow access
            return true;
        } else {
            // console.log('AuthGuard', isAuthenticated);
            // User is not authenticated, redirect to sign-in page
            const redirectURL =
                state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
            return router.parseUrl(`auth/sign-in?${redirectURL}`);
        }
    } catch (error) {
        console.error('AuthGuard error:', error);
        // In case of an error, redirect to sign-in page
        return router.parseUrl('auth/sign-in');
    }
};
