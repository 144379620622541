<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex w-full items-center p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-14" src="images/logos/logo.png" />
            </div>
            <!-- Components -->
            <div class="ml-auto flex items-center">
                <!-- <notifications></notifications> -->
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex w-full flex-col items-center p-4">
            <div class="relative h-24 w-24">
                @if (koalaUser?.profile_image && profileImageUrl) {
                    <img
                        #profileImage
                        crossorigin="anonymous"
                        class="h-full w-full rounded-full"
                        [src]="profileImageUrl"
                        alt="User avatar"
                    />
                }
                @if (!koalaUser?.profile_image) {
                    <mat-icon
                        class="icon-size-24"
                        [svgIcon]="'heroicons_solid:user-circle'"
                    ></mat-icon>
                }
            </div>
            <div class="mt-6 flex w-full flex-col items-center justify-center">
                <div
                    class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-medium leading-normal"
                >
                    {{ user?.firstname }}
                </div>
                <div
                    class="text-secondary mt-0.5 w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-md font-medium leading-normal"
                >
                    {{ user?.email }}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div
            class="mb-4 mt-2 flex h-16 flex-0 items-center justify-center pl-2 pr-6 opacity-12"
        >
            <img class="max-w-36" src="images/logos/logo.png" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <button
                mat-button
                (click)="openFAQ()"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="iso text-uppercase">FAQ</span>
                </div>
            </button>
            <div class="toolbar-separator h-16"></div>
            <languages></languages>
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left-right'"
                ></mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div
        class="bg-card relative z-49 flex h-14 flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <div style="display: flex; flex: 1; justify-content: center">
            <div>
                <span class="text-secondary pr-2 font-medium"
                    >ÉquipeNutrition &copy; {{ currentYear }}</span
                >
                <span>&bull;</span>
                <a
                    class="primaryLink p-2"
                    href="https://equipenutrition.ca"
                    target="_blank"
                    >equipenutrition.ca</a
                >
                <span>&bull;</span>
                <a
                    class="primaryLink p-2"
                    href="https://fit.koalapro.com"
                    target="_blank"
                    >fit.koalapro.com</a
                >
                <span>&bull;</span>
                <a
                    class="primaryLink p-2"
                    href="https://teamnutrition.ca"
                    target="_blank"
                    >teamnutrition.ca</a
                >
                <span>&bull;</span>
                <a
                    class="primaryLink p-2"
                    href="https://jecoutemafaim.ca"
                    target="_blank"
                    >jecoutemafaim.ca</a
                >
            </div>
        </div>
    </div>
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
