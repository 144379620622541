import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, Input,
    OnDestroy,
    OnInit, ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { KoalaUserService } from 'app/core/koalaUser/koalaUser.service';
import {
    KoalaUser,
    KoalaUserResponse,
} from 'app/core/koalaUser/koalaUser.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { CognitoService } from 'app/core/cognito/cognito.service';
import { environment } from 'environments/environment';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
        TranslocoModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy, AfterViewInit {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;

    user: User;
    koalaUser: KoalaUser;
    member: boolean = false;
    returnUrl = '/client/profile';
    returnUrlMember = '/exclusive-content/workouts';
    profileImageUrl: string = 'images/avatars/profile.jpg';
    // @ViewChild('profileImage', { static: false })
    // profileImageElement!: ElementRef<HTMLImageElement>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private cognitoService: CognitoService,
        private _koalaUserService: KoalaUserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log('user - ngOnInit');
        // Subscribe to user changes
        // this._userService.user$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: User) => {
        //         this.user = user;

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });

        this._koalaUserService
            .get()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: KoalaUserResponse) => {
                // console.log('koalauser', user);
                this.koalaUser = user.data.user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // this._koalaUserService.get().;
        this._userService
            .getCurrentUserInfo()
            .pipe(first())
            .subscribe({
                next: (user) => {
                    // console.log('user', user);
                    this.user = user;
                    if (user) {
                        // if (user.language) {
                        //     this._translateService.setDefaultLang(
                        //         user.language
                        //     );
                        //     this._translateService.setActiveLang(user.language);
                        // }
                        this.member = user.roles.includes('member');

                        // if (this.member) {
                        //     this._router.navigate([this.returnUrlMember]);
                        // } else {
                            // this._router.navigate([this.returnUrl]);
                        // }
                    } else {
                        this.cognitoService.logout();
                    }
                },
                error: (error) => {},
            });

        // this._translateService.langChanges$
        //     .subscribe({
        //         next: (lang) => {
        //             console.log('User - langChanges$', lang);
        //         }, error: (error) => {
        //             console.error('User - langChanges$', error)
        //         }
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        if (this.showAvatar) {
            this.loadProfileImage();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Update the user status
    //  *
    //  * @param status
    //  */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // // Update the user
        // this._userService
        //     .update({
        //         ...this.user,
        //         status,
        //     })
        //     .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    openFit(): void {
        // Assuming you have environment.fit_base_url defined in your environment files
        window.open(environment.fitUrl);
    }

    loadProfileImage(): void {
        this._koalaUserService.getProfileImage().subscribe({
            next: (url) => {
                // Convert the Blob to a safe URL
                // const objectUrl = URL.createObjectURL(blob); // Create a Blob URL
                this.profileImageUrl = url;
                // if (this.profileImageElement)
                //     this.profileImageElement.nativeElement.src = url; // Assign Blob URL directly to the `src`
            },
            error: () => {
                console.error('Failed to load profile image');
            },
        });
    }
}
