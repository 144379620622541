import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoService } from '@jsverse/transloco';
import { KoalaUserService } from 'app/core/koalaUser/koalaUser.service';
import { KoalaUser } from 'app/core/koalaUser/koalaUser.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { environment } from 'environments/environment';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        NotificationsComponent,
        UserComponent,
        MatIconModule,
        MatButtonModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        RouterOutlet,
        QuickChatComponent,
        MatToolbarModule,
        MatMenuModule
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    koalaUser: KoalaUser;
    profileImageBaseUrl = environment.koalaProBackendUrl;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    profileImageUrl: string = '';
    // @ViewChild('profileImage', { static: true })
    // profileImageElement!: ElementRef<HTMLImageElement>;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _koalaUserService: KoalaUserService,
        private _translateService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log('classy layout - ngOnInit');
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        // this._userService.getCurrentUserInfo()
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: User) => {
        //         this.user = user;
        //     });

        this._koalaUserService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: KoalaUser) => {
                // console.log('koalauser', user);
                this.koalaUser = user;
            });

        // this._koalaUserService.get();

        this._userService.user$
            .subscribe({
                next: (user) => {
                    // console.log('classy component - user', user);
                    this.user = user;
                    if (user) {
                        // if (user.language) {
                        //     this._translateService.setDefaultLang(
                        //         user.language
                        //     );
                        //     this._translateService.setActiveLang(user.language);
                        // }
                        // this.member = user.roles.includes('member');
                        // if (this.member) {
                        //     this._router.navigate([this.returnUrlMember]);
                        // } else {
                        //     this._router.navigate([this.returnUrl]);
                        // }
                    } else {
                        // this.cognitoService.logout();
                    }
                },
                error: (error) => {},
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        this.loadProfileImage();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    loadProfileImage(): void {
        this._koalaUserService.getProfileImage().subscribe({
            next: (url) => {
                // Convert the Blob to a safe URL
                // const objectUrl = URL.createObjectURL(blob); // Create a Blob URL
                // console.log('url', url);
                this.profileImageUrl = url;
            },
            error: () => {
                console.error('Failed to load profile image');
            },
        });
    }

    openFAQ(): void {
        if(this._translateService.getActiveLang() === 'en') {
            window.open("https://teamnutrition.ca/faq", "_blank");
        }
        else {
            window.open('https://equipenutrition.ca/faq', '_blank');
        }
    }
}
