import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { CognitoService } from 'app/core/cognito/cognito.service';
import { Observable, catchError, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const cognitoService = inject(CognitoService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.

    const isExpired = AuthUtils.isTokenExpired(
        cognitoService.currentTokenValue?.id_token
    );
    // console.log(
    //     'authInterceptor - id token - expired',
    //     cognitoService?.currentTokenValue?.id_token,
    //     isExpired
    // );
    // console.log('Auth intercept');
    if (cognitoService.currentTokenValue) {
        if (!isExpired) {
            // console.log(
            //     'authInterceptor - id token',
            //     cognitoService.currentTokenValue?.id_token
            // );
            newReq = req.clone({
                headers: req.headers.set(
                    'AuthorizationMO',
                    'Bearer ' + cognitoService.currentTokenValue?.id_token
                ),
            });
        } else {
            console.log('not valid id token and expired');
            cognitoService.getCurrentSession(false).then(() => {
                if (
                    cognitoService?.currentTokenValue?.id_token &&
                    !AuthUtils.isTokenExpired(
                        cognitoService?.currentTokenValue?.id_token
                    )
                ) {
                    newReq = req.clone({
                        headers: req.headers.set(
                            'AuthorizationMO',
                            'Bearer ' + cognitoService.currentTokenValue?.id_token
                        ),
                    });
                } else {
                    cognitoService.logout();
                    console.log('still not valid id token and expired');

                }
            });
        }
    }

    // console.log('authInterceptor - headera', newReq.headers);

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                cognitoService.signOut().then(() => {
                    console.log('authInterceptor', 'Sign out');
                    // Reload the app
                    // location.reload();
                });
            }
            // console.error('AuthInterceptor');
            return throwError(() => error);
        })
    );
};
