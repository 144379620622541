<div class="w-full h-full not-found-container">
    <div>
        <!-- <div class="logo">
            <img width="128" src="images/logos/logo.png" />
        </div> -->

        <h1>404</h1>
        <p>{{ 'not_found.message' | transloco }}</p>
        
            <a routerLink="/">
              <button mat-flat-button color="primary">{{ 'not_found.go_home' | transloco }}</button></a>
        <!-- / Material Design Spinner -->
    </div>
  </div >
