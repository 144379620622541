import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  standalone: true,
  imports: [RouterModule, TranslocoModule, MatButtonModule],
})
export class NotFoundComponent {

  constructor(public fuseSplashService: FuseSplashScreenService) {}

  goHome() {
    window.location.href = '/'; // This will take the user to the home page
  }
}
