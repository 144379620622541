import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { BillingService } from './core/billing/billing.service';
import { ClientService } from './core/client/client.service';
import { DossierService } from './core/dossiers/dossiers.service';
import { NotFoundComponent } from './components/not-found/not-found.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'confirm-reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirm-reset-password/confirm-reset-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            // {
            //     path: 'unlock-session',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/unlock-session/unlock-session.routes'
            //         ),
            // },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    // import('app/modules/client/example/example.routes'),
                    import(
                        'app/modules/client/exclusive-content/workouts/workouts-home.routes'
                    ),
            },
            {
                path: 'client/profile',
                loadChildren: () =>
                    import('app/modules/client/profile/profile.routes'),
            },
            {
                path: 'client/health',
                loadChildren: () =>
                    import(
                        'app/modules/client/landing pages/personal-information/personal-information.routes'
                    ),
            },
            {
                path: 'exclusive-content/workouts',
                loadChildren: () =>
                    import(
                        'app/modules/client/exclusive-content/workouts/workouts-home.routes'
                    ),
            },
            {
                path: 'exclusive-content/recipes',
                loadChildren: () =>
                    import(
                        'app/modules/client/exclusive-content/recipes/recipes.routes'
                    ),
            },
            {
                path: 'exclusive-content/nutrition',
                loadChildren: () =>
                    import(
                        'app/modules/client/exclusive-content/nutrition/nutrition.routes'
                    ),
            },
            {
                path: 'exclusive-content/articles',
                loadChildren: () =>
                    import(
                        'app/modules/client/exclusive-content/articles/articles.routes'
                    ),
            },
            {
                path: 'exclusive-content/coupons',
                loadChildren: () =>
                    import(
                        'app/modules/client/exclusive-content/coupons/coupons.routes'
                    ),
            },
            {
                path: 'client/koalapro',
                loadChildren: () =>
                    import(
                        'app/modules/client/koalapro-page/koalapro-page.routes'
                    ),
            },
            {
                path: 'client/rewards',
                loadChildren: () =>
                    import('app/modules/client/rewards/rewards.routes'),
            },
            {
                path: 'client/billing',
                loadChildren: () =>
                    import('app/modules/client/billing/billing.routes'),
                resolve: {
                    data: BillingService,
                },
            },
            {
                path: 'client/files',
                loadChildren: () =>
                    import('app/modules/client/files/files.routes'),
                resolve: {
                    data: DossierService,
                },
            },
            {
                path: 'client/subscriptions',
                loadChildren: () =>
                    import(
                        'app/modules/client/subscriptions/subscriptions.routes'
                    ),
                resolve: {
                    data: ClientService,
                },
            },
            {
                path: 'client/30-days-challenge',
                loadChildren: () => 
                    import('app/modules/client/mail-chimp/mail-chimp.routes'),
            },
            {
                path: 'suggestions',
                loadChildren: () =>
                    import('app/modules/client/contact-us/contact-us.routes'),
            },
            { path: '**', component: NotFoundComponent },
        ],
    },
];
